import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const ProtectedRoute = ({ children }) => {

    const { isLoggedIn } = useSelector(state => state.authSlice)
    const navigate = useNavigate()

    useEffect(() => {

        if (isLoggedIn) {
            navigate('/')
        }

    }, [isLoggedIn])

    return !isLoggedIn && children

}

export default ProtectedRoute
