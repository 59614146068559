import {ThemeProvider} from "@emotion/react";
import {StandardInputTheme} from "../Auth/StandardInputTheme";
import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";

const CheckoutAddress = () => {
    return (
        <ThemeProvider theme={StandardInputTheme}>
            <div className="my-3 billing-address">
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        className="input-border-color"
                        id="standard-basic"
                        label="Full Name"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="Email"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="Phone"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="Street Address"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="City/Town"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="State"
                        variant="outlined"
                    />
                </div>
                <div className="input-group mb-4">
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label="Postcode"
                        variant="outlined"
                    />
                </div>
            </div>
        </ThemeProvider>
    );
};

export default CheckoutAddress;
