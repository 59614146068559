import { Button } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React from "react";
import Logo from "../../shared/images/logo.png";

const PDFGenerator = ({ data }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Define a fixed logo height and calculate width to maintain aspect ratio
    const logoHeight = 30;
    const logoWidth = 30; // Adjusted manually for a balanced display

    // Check if the logo is properly loaded and add it to the document
    const logoImage = new Image();
    logoImage.src = Logo;
    logoImage.onload = () => {
      // Add logo on the top left and "SwedeBuy" on the top right
      doc.addImage(logoImage, "PNG", 10, 10, logoWidth, logoHeight);
      doc.setFontSize(18);
      doc.text("SwedeBuy", doc.internal.pageSize.width - 50, 20, { align: "right" });

      // Document Title
      doc.setFontSize(16);
      doc.text("Order Summary", 10, 50);

      // User Details Table
      doc.autoTable({
        startY: 60,
        theme: 'grid',
        head: [["User Details", ""]],
        body: [
          ["Name", `${data.userId.first_name} ${data.userId.last_name}`],
          ["Email", data.userId.email]
        ],
        headStyles: { fillColor: [225, 225, 225] },
      });

      // Shipping Details Table
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        head: [["Shipping Details", ""]],
        body: [
          ["Address", data.shippingAddress.addressLine1],
          ["Postal Code", data.shippingAddress.postalCode],
          ["Country", data.shippingAddress.country],
          ["Phone", data.shippingAddress.phone]
        ],
        headStyles: { fillColor: [225, 225, 225] },
      });

      // Payment and Shipping Status Table
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        head: [["Payment and Shipping Status", ""]],
        body: [
          ["Payment Method", data.paymentMethod],
          ["Payment Status", data.paymentStatus],
          ["Shipping Status", data.shippingStatus]
        ],
        headStyles: { fillColor: [225, 225, 225] },
      });

      // Order Items Table
      const items = data.items.map((item) => [
        item.productId.name,
        item.quantity,
        `${item.price.toFixed(2)} KR`,
        `${item.totalItemPrice.toFixed(2)} KR`
      ]);

      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'grid',
        head: [["Product", "Quantity", "Price (KR)", "Total (KR)"]],
        body: items,
        headStyles: { fillColor: [225, 225, 225] },
      });

      // Total Amount
      doc.setFontSize(14);
      doc.text(`Total Amount: ${data.totalAmount.toFixed(2)} KR`, 10, doc.lastAutoTable.finalY + 10);

      // Save the PDF
      doc.save("order-summary.pdf");
    };

    logoImage.onerror = () => {
      console.error("Failed to load the logo image.");
    };
  };

  return (
    <Button variant="outlined" color="info" className="print-order-button" onClick={generatePDF}>
      Print Order
    </Button>
  );
};

export default PDFGenerator;
