import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const categoryApiService = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}` }), // Base URL for your API
  endpoints: (builder) => ({
    getAllCategoriesWithSubcategories: builder.query({
      query: () => "category/getCategoriesWithSubcategory", 
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0, // Do not cache any unused data// The specific endpoint
    }),
  }),
});

export const { useGetAllCategoriesWithSubcategoriesQuery } = categoryApiService;
