import React, { useState, useEffect } from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { useTranslation } from 'react-i18next';
import countryData from '../Cart/Country.json';  // Assuming your country file is in the same directory
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; // Import the package

const ShippingAddressForm = React.memo(({ shippingAddress, onAddressChange, errors }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  const APIKEY = process.env.REACT_APP_GOOGLE_API

  useEffect(() => {
    // Load countries data from JSON file
    setCountries(countryData);
  }, []);

  // Custom postal code handler to ensure only 5 digits
  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,5}$/.test(value)) { // Only allow digits and limit to 5
      onAddressChange("postalCode", value);
    }
  };

  return (
    <div className="shipping-section">
      <h4 className="checkout-title">{t("ShipAdd")}</h4>
      <h5 className="checkout-title">Vi skickar endast till Sverige</h5>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GooglePlacesAutocomplete
            selectProps={{
              value: shippingAddress.addressLine1
                ? { label: shippingAddress.addressLine1, value: shippingAddress.addressLine1 }
                : null,
              onChange: (value) => {
                if (value) {
                  // Set the address in the form and display the label in the input field
                  onAddressChange("addressLine1", value.label);
                }
              },
              styles: {
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#ffffff", // White background for the input
                  border: "1px solid #ccc", // Light gray border
                  borderRadius: "4px", // Rounded corners
                  padding: "5px", // Padding inside the input
                  boxShadow: "none", // Remove default shadow
                }),
                input: (provided) => ({
                  ...provided,
                  color: "#333", // Text color
                  fontSize: "16px", // Font size for input text
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#888", // Placeholder text color
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#333", // Selected value text color
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "#ffffff", // White background for suggestions dropdown
                  borderRadius: "4px", // Rounded corners for dropdown
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for dropdown
                  marginTop: "4px", // Space between input and dropdown
                  opacity: 1, // Ensure full opacity for the dropdown menu
                  zIndex: 1000, // Set a higher z-index to ensure it appears above other elements
                }),
                option: (provided, state) => ({
                  ...provided,
                  padding: "10px", // Padding for each suggestion
                  cursor: "pointer", // Cursor for clickable effect
                  backgroundColor: state.isFocused ? "#e6f7ff" : "#ffffff", // Ensure white background for suggestions
                  color: "#333", // Text color
                  opacity: 1, // Ensure full opacity for each option
                  border: "none", // Remove borders to ensure the clean appearance
                  transition: "background-color 0.2s ease", // Smooth background transition
                }),
              },
            }}
            apiKey={APIKEY} // Replace with your Google API key
            autocompletionRequest={{
              componentRestrictions: {
                country: ["se"], // Set your desired country here
              },
            }}
            placeholder="Enter your address"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Postal Code *"
            value={shippingAddress.postalCode}
            onChange={handlePostalCodeChange}
            error={!!errors.postalCode}
            helperText={errors.postalCode}
            fullWidth
            margin="none" // Change this as needed
            inputProps={{ maxLength: 5 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Phone Number *"
            value={shippingAddress.phone}
            onChange={(e) => onAddressChange("phone", e.target.value)}
            error={!!errors.phone}
            helperText={errors.phone}
            fullWidth
            margin="none" // Change this as needed
          />
        </Grid>
      </Grid>

    </div>
  );
});

export default ShippingAddressForm;
