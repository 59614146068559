import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'

const AboutPage = () => {
  return (
    <div>
        <About />
    </div>
  )
}

export default AboutPage