import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAddUserAddressMutation, useDeleteUserAddressMutation, useGetUserAddressesQuery, useUpdateUserAddressMutation } from '../../redux/Apis/User';
import { useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; // Import the package
import { toast } from 'react-toastify';
function AddressCard({ elem, refetchShippingAddresses }) {
    const { userId } = useSelector((state) => state.authSlice);
    const [deleteUserAddress, { isLoading: isDeleting, isError: isDeleteError }] = useDeleteUserAddressMutation();
    const [open, setOpen] = useState(false);
    const [editedData, setEditedData] = useState({
        phone: elem.phone,
        addressLine1: elem.addressLine1,
        postalCode: elem.postalCode
    });

    // Toggle dialog visibility
    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    // Handle changes in the form fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const [updateUserAddress, { isLoading: isUpdating, isError: isUpdatingError }] = useUpdateUserAddressMutation();

    // Handle save button in the dialog
    const handleSave = () => {
        // Handle saving the edited data here, e.g., API call or state update
   
        updateUserAddress({
            userId: userId,
            addressId: elem._id,
            addressLine1: editedData.addressLine1,
            postalCode: editedData.postalCode,
            phone: editedData.phone,
        }).unwrap().then(() => {
            // Refetch the shipping addresses after a successful delete
            toast.success("Address Updated Succesfully")
            refetchShippingAddresses();
            setOpen(false);
        })

            .catch((error) => {
                console.error("Error updating address:", error);
            });
    };

    const handleDelete = () => {
        if (elem._id) { // Ensure elem.id exists
            deleteUserAddress({ userId, addressId: elem._id })
                .unwrap() // If you want to handle the response
                .then(() => {
                    // Refetch the shipping addresses after a successful delete
                    toast("Address deleted succesfully")
                    refetchShippingAddresses();
                })
                .catch((error) => {
                    console.error("Error deleting address:", error);
                });
        }
    };

    const APIKEY = process.env.REACT_APP_GOOGLE_API;

    return (
        <div>
            <Card variant="outlined" sx={{ margin: "1rem 0" }}>
                <CardContent>
                    <Typography color="textPrimary" fontSize={"1rem"}>
                        {elem.phone}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        fontSize="1rem"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {elem.addressLine1}
                    </Typography>

                    <Typography color="textSecondary" fontSize={"1rem"}>
                        {elem.postalCode}
                    </Typography>
                    <div className="my-2">
                        <DeleteIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            className="me-1"
                            onClick={handleDelete} // Attach delete handler
                        />
                        <EditIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            className="me-1"
                            onClick={handleDialogOpen} // Open dialog for editing
                        />
                    </div>
                </CardContent>
            </Card>

            {/* Dialog for editing */}
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>Edit Information</DialogTitle>
                <DialogContent>

                    <GooglePlacesAutocomplete
                        apiKey={APIKEY} // Replace with your Google API key
                        selectProps={{
                            value: editedData?.addressLine1
                                ? { label: editedData.addressLine1, value: editedData.addressLine1 }
                                : null,
                            onChange: (value) => {
                                if (value) {
                                    // Set the address in the form and display the label in the input field
                                    setEditedData((prev) => ({
                                        ...prev,
                                        addressLine1: value.label,
                                    }));
                                }
                            },
                            styles: {
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#ffffff", // White background for the input
                                    border: "1px solid #ccc", // Light gray border
                                    borderRadius: "4px", // Rounded corners
                                    padding: "5px", // Padding inside the input
                                    boxShadow: "none", // Remove default shadow
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: "#333", // Text color
                                    fontSize: "16px", // Font size for input text
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#888", // Placeholder text color
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: "#333", // Selected value text color
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#ffffff", // White background for suggestions dropdown
                                    borderRadius: "4px", // Rounded corners for dropdown
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for dropdown
                                    marginTop: "4px", // Space between input and dropdown
                                    opacity: 1, // Ensure full opacity for the dropdown menu
                                    zIndex: 1000, // Set a higher z-index to ensure it appears above other elements
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    padding: "10px", // Padding for each suggestion
                                    cursor: "pointer", // Cursor for clickable effect
                                    backgroundColor: state.isFocused ? "#e6f7ff" : "#ffffff", // Ensure white background for suggestions
                                    color: "#333", // Text color
                                    opacity: 1, // Ensure full opacity for each option
                                    border: "none", // Remove borders to ensure the clean appearance
                                    transition: "background-color 0.2s ease", // Smooth background transition
                                }),
                            },
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ["se"], // Restrict to Sweden addresses
                            },
                        }}
                        placeholder="Enter your address" // Placeholder text
                    />
                    <TextField
                        label="Phone"
                        fullWidth
                        name="phone"
                        value={editedData.phone}
                        onChange={handleInputChange}
                        sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                    />
                    <TextField
                        label="Postal Code"
                        fullWidth
                        name="postalCode"
                        value={editedData.postalCode}
                        onChange={handleInputChange}
                        sx={{ marginBottom: "1rem" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const AddressList = () => {
    const { userId, user } = useSelector((state) => state.authSlice);
    const [open, setOpen] = useState(false);
    const { data: shippingAddresses, refetch: refetchShippingAddresses, isLoading: isShippingLoading, isError: isShippingError } = useGetUserAddressesQuery(userId, {
        refetchOnMountOrArgChange: true, // Refetch on mount or if the userId changes
        keepUnusedDataFor: 0, // Do not keep unused data
    });
    const [addAddress, { isLoading: isUpdating, isError: isUpdatingError }] = useAddUserAddressMutation();
    // Check for loading state
    const [addressLine1, setAddressLine1] = useState('');

    const [editedData, setEditedData] = useState({ addressLine1: '' });
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');

    if (isShippingLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }


    const APIKEY = process.env.REACT_APP_GOOGLE_API;


    // Check for error state
    if (isShippingError) {
        return <Typography color="error">Error loading addresses</Typography>;
    }

    const addresses = shippingAddresses?.data || []; // Safely access addresses




    // Open dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Close dialog
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAddress = async () => {
        // Use the mutation function to add address
        try {
            await addAddress({
                userId,
                addressLine1: editedData.addressLine1,
                postalCode,
                phone,
                country: "se"
            });
            refetchShippingAddresses();
            setPostalCode("")
            setPhone("")
            toast.success("Address added succesfully")
            setEditedData({ addressLine1: "" })
            handleClose(); // Close the dialog on success
        } catch (error) {
            console.error("Error adding address:", error);
        }
    };

    return (
        <Box sx={{ margin: 'auto', width: "100%" }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ marginBottom: 2 }}>
                Add Address
            </Button>

            {addresses.length > 0 ? (
                <Grid container spacing={2}>
                    {addresses.map((elem) => (
                        <Grid item xs={12} sm={6} key={elem._id}>
                            <AddressCard elem={elem} refetchShippingAddresses={refetchShippingAddresses} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography>No addresses found.</Typography>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Address</DialogTitle>
                <DialogContent>

                    <GooglePlacesAutocomplete
                        apiKey={APIKEY} // Replace with your Google API key
                        selectProps={{
                            value: editedData?.addressLine1
                                ? { label: editedData.addressLine1, value: editedData.addressLine1 }
                                : null,
                            onChange: (value) => {
                                if (value) {
                                    setEditedData((prev) => ({
                                        ...prev,
                                        addressLine1: value.label,
                                    }));
                                }
                            },
                            placeholder: "Enter your address",
                            styles: {
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "5px",
                                    boxShadow: "none",
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: "#333",
                                    fontSize: "16px",
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#888",
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: "#333",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#ffffff",
                                    borderRadius: "4px",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    marginTop: "4px",
                                    zIndex: 1000,
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    padding: "10px",
                                    cursor: "pointer",
                                    backgroundColor: state.isFocused ? "#e6f7ff" : "#ffffff",
                                    color: "#333",
                                    transition: "background-color 0.2s ease",
                                }),
                            },
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ["se"], // Restrict to Sweden addresses
                            },
                        }}
                    />

                    <TextField
                        margin="dense"
                        label="Postal Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        inputProps={{ maxLength: 5 }} // Limit input to 5 characters
                    />
                    <TextField
                        margin="dense"
                        label="Phone"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddAddress}
                        color="primary"
                        disabled={isUpdating || !editedData?.addressLine1 || postalCode.length !== 5 || !phone}
                    >
                        {isUpdating ? 'Adding...' : 'Add Address'}
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default AddressList;