import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import VerifyEmail from '../components/EmailVerify/EmailVerify'

const VerifyEmailPage = () => {
  return (
    <div>
        <VerifyEmail />
    </div>
  )
}

export default VerifyEmailPage