import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import Product from '../components/ProductComponent/Product'
import ProductSearch from '../components/Search/ProductSearch'

const SearchPage = () => {
  return (
    <div>
        <ProductSearch />
    </div>
  )
}

export default SearchPage