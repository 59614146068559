import React from "react";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"; // Import useSelector to access Redux state
import { useTranslation } from 'react-i18next';
const CartTotal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Get cart items from Redux store
  const cartItems = useSelector((state) => state.cartSlice.items);

  // Calculate subtotal dynamically using base price or discounted price
  const subtotal = cartItems.reduce((total, item) => {
    const itemTotal = item.basePrice * item.quantity;

    // Apply discount if applicable
    const discountedTotal =
      item.quantityDiscount && item.quantity >= item.quantityDiscount.quantity
        ? itemTotal * (1 - item.quantityDiscount.discount / 100)
        : itemTotal;

    return total + discountedTotal;
  }, 0);

  // Define a flat shipping rate
  const shippingCost = 50; // Flat shipping rate in kr

  // Calculate total cost
  const total = subtotal;

  return (
    <div className="cart-container">
      <h3>
      {t("Cart Total")}
      </h3>
      <Divider className="my-3" sx={{ borderColor: "#000" }} />

      {/* Subtotal Section */}
      <div className="car-pricing">
        <p>
        {t("SubTot")}
        </p>
        <p>{`${subtotal.toFixed(2)} kr`}</p>
      </div>
      <Divider className="my-3" sx={{ borderColor: "#000" }} />

      {/* Shipping Section */}
      
      

      {/* Total Section */}
      <div className="car-pricing">
        <p className="total-pricing">Total</p>
        <p className="total-pricing">{`${total.toFixed(2)} kr`}</p>
      </div>

      
      {/* Shipping Information */}

      {/* Checkout Button */}
      <div className="d-flex justify-content-center">

      </div>
    </div>
  );
};

export default CartTotal;
