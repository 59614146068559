// src/redux/Slices/CategorySlice.js
import { createSlice } from "@reduxjs/toolkit";

// Initial category state
const initialState = {
    categories: JSON.parse(sessionStorage.getItem('categories')) || [], // Load categories from sessionStorage
    loading: false,
    error: null,
};

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
            state.loading = false;
            state.error = null;

            // Persist updated categories to sessionStorage
            sessionStorage.setItem('categories', JSON.stringify(state.categories));
        },
        addCategory: (state, action) => {
            state.categories.push(action.payload);

            // Persist updated categories to sessionStorage
            sessionStorage.setItem('categories', JSON.stringify(state.categories));
        },
        updateCategory: (state, action) => {
            const { id, updatedCategory } = action.payload;
            const index = state.categories.findIndex((category) => category.id === id);

            if (index !== -1) {
                state.categories[index] = updatedCategory;

                // Persist updated categories to sessionStorage
                sessionStorage.setItem('categories', JSON.stringify(state.categories));
            }
        },
        removeCategory: (state, action) => {
            const { id } = action.payload;
            state.categories = state.categories.filter((category) => category.id !== id);

            // Persist updated categories to sessionStorage
            sessionStorage.setItem('categories', JSON.stringify(state.categories));
        },
        clearCategories: (state) => {
            state.categories = [];
            state.loading = false;
            state.error = null;

            // Clear categories from sessionStorage
            sessionStorage.setItem('categories', JSON.stringify(state.categories));
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

// Export actions for use in components
export const {
    setCategories,
    addCategory,
    updateCategory,
    removeCategory,
    clearCategories,
    setLoading,
    setError,
} = categorySlice.actions;

// Export the reducer to add to the store
export default categorySlice.reducer;
