import { createTheme } from "@mui/material";
export const StandardInputTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          // Standard
          "& .MuiInput-root": {
            color: "#111111",
            fontFamily: "Arial",
            fontWeight: "bold",
            "&:before": {
              borderColor: "#000",
              borderWidth: "2px",
            },
            "&:after": {
              borderColor: "#000",
              borderWidth: "3px",
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "#000",
                borderWidth: "2px",
              },
            },
          },
          "& .MuiInputLabel-standard": {
            "&.Mui-focused": {
              color: "#000",
            },
          },
        },
      },
      
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          // Standard
          "& .MuiInput-root": {
            color: "#111111",
            fontFamily: "Arial",
            fontWeight: "bold",
            "&:before": {
              borderColor: "#000",
              borderWidth: "2px",
            },
            "&:after": {
              borderColor: "#000",
              borderWidth: "3px",
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "#000",
                borderWidth: "2px",
              },
            },
          },
          "& .MuiInputLabel-standard": {
            "&.Mui-focused": {
              color: "#000",
            },
          },
        },
      },
      
    },
  },
});