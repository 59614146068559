import { createSlice } from "@reduxjs/toolkit"
import { authApiService } from "../../Apis/Auth"

let initialState = {
    isLoggedIn: false,
    token: null,
    user: null,
    userId: null, // Stores userId separately
};

export const AuthSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
            state.token = null;
            state.user = null;
            state.userId = null; // Clear userId on logout
        },
        updateUser: (state, action) => {
            const { first_name, last_name, phone } = action.payload;
            if (state.user) {
                state.user.first_name = first_name;
                state.user.last_name = last_name;
                state.user.phone = phone;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(authApiService.endpoints.login.matchFulfilled, (state, action) => {
            let { success, token, data } = action?.payload;
            if (success) {
                state.isLoggedIn = success;
                state.token = token;
                state.user = data;
                state.userId = data?._id;
            } else {
                state.isLoggedIn = false;
                state.token = null;
                state.user = null;
                state.userId = null; // Clear userId if login fails
            }
        });
    }
});

export const { logout, updateUser } = AuthSlice.actions;
export default AuthSlice.reducer;
