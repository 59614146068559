import { createSlice } from "@reduxjs/toolkit";

// Initial cart state
const initialState = {
    items: JSON.parse(sessionStorage.getItem('cart')) || [], // Load from sessionStorage
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItemToCart: (state, action) => {
            const { productId, size, color, variation, price, sku, quantityDiscount = null, stock } = action.payload; // Ensure stock is included
            let { quantity } = action.payload; // Change to let to allow reassignment
            const existingItemIndex = state.items.findIndex(
                (item) =>
                    item.productId === productId &&
                    item.size === size &&
                    item.variation === variation &&
                    item.sku === sku
            );
        
            if (existingItemIndex >= 0) {
                // Check if the new quantity exceeds available stock
                const newQuantity = state.items[existingItemIndex].quantity + quantity;
                if (newQuantity > stock) {
                    // Adjust the quantity to the maximum allowed
                    quantity = stock - state.items[existingItemIndex].quantity; // Limit quantity to available stock
                }
        
                // Update the existing item quantity
                state.items[existingItemIndex].quantity += quantity;
        
                // Recalculate the discounted price based on the new quantity
                if (quantityDiscount && state.items[existingItemIndex].quantity >= quantityDiscount.quantity) {
                    state.items[existingItemIndex].discountedPrice = (1 - quantityDiscount.discount / 100) * state.items[existingItemIndex].basePrice;
                    state.items[existingItemIndex].appliedDiscount = quantityDiscount;
                } else {
                    state.items[existingItemIndex].discountedPrice = state.items[existingItemIndex].basePrice;
                    state.items[existingItemIndex].appliedDiscount = null;
                }
            } else {
                // If it's a new item, check if the quantity exceeds stock
                if (quantity > stock) {
                    quantity = stock; // Limit quantity to available stock
                }
        
                let itemPrice = price;
                let discountedPrice = itemPrice;
                if (quantityDiscount && quantity >= quantityDiscount.quantity) {
                    discountedPrice = (1 - quantityDiscount.discount / 100) * itemPrice;
                }
        
                state.items.push({
                    ...action.payload,
                    basePrice: itemPrice,
                    discountedPrice: discountedPrice,
                    quantity,
                    quantityDiscount: quantityDiscount,
                    appliedDiscount: quantityDiscount || null,
                });
            }
        
            sessionStorage.setItem('cart', JSON.stringify(state.items));
        },
        


        removeItemFromCart: (state, action) => {
            const { productId, size, color, variation, sku } = action.payload; // Ensure SKU is included
            state.items = state.items.filter(
                (item) =>
                    !(
                        item.productId === productId &&
                        item.size === size &&

                        item.variation === variation
                    )
            );

            // Persist updated cart to sessionStorage
            sessionStorage.setItem('cart', JSON.stringify(state.items));
        },

        updateItemQuantity: (state, action) => {
            const { productId, size, color, variation, quantity, sku, quantityDiscount = null, stock } = action.payload; // Ensure stock is included
           
            const existingItemIndex = state.items.findIndex(
                (item) =>
                    item.productId === productId &&
                    item.coloe === color
            );

            if (existingItemIndex >= 0) {
                // Check if the new quantity exceeds available stock
                const newQuantity = quantity > stock ? stock : quantity; // Limit to stock if it exceeds

                if (newQuantity > 0) {
                    // Update the quantity of the existing item
                    state.items[existingItemIndex].quantity = newQuantity;

                    // Recalculate the discounted price based on the new quantity
                    if (quantityDiscount && newQuantity >= quantityDiscount.quantity) {
                        state.items[existingItemIndex].discountedPrice = (1 - quantityDiscount.discount / 100) * state.items[existingItemIndex].basePrice;
                        state.items[existingItemIndex].appliedDiscount = quantityDiscount; // Store the applied discount
                    } else {
                        // Reset discounted price to base price if discount no longer applies
                        state.items[existingItemIndex].discountedPrice = state.items[existingItemIndex].basePrice;
                        state.items[existingItemIndex].appliedDiscount = null; // Remove any applied discount
                    }
                } else {
                    // If quantity is 0 or less, remove the item
                    state.items.splice(existingItemIndex, 1);
                }
            } else {
                console.error("Item not found in cart for update."); // Debugging log if item is not found
            }

            // Persist updated cart to sessionStorage
            sessionStorage.setItem('cart', JSON.stringify(state.items));
        },

        clearCart: (state) => {
            state.items = [];
            // Persist updated cart to sessionStorage
            sessionStorage.setItem('cart', JSON.stringify(state.items));
        },
    },
});

// Export actions for use in components
export const { addItemToCart, removeItemFromCart, updateItemQuantity, clearCart } = cartSlice.actions;

// Export the reducer to add to the store
export default cartSlice.reducer;
