import React, { useState } from "react";
import { useSelector } from "react-redux";
// Adjust import based on your API service location
import { Box, TextField, Button, Pagination } from "@mui/material"; // Assuming you're using Material-UI for UI components
import {
  useGetSingleOrderQuery,
  useReturnOrderMutation,
} from "../../redux/Apis/Order";
import "./MyOrder.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Rating,
  Grid
} from "@mui/material";
import { useAddProductReviewMutation } from "../../redux/Apis/Review";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import PDFGenerator from "./PDFGenerator";

const MyOrder = () => {
  const { userId } = useSelector((state) => state.authSlice);
  const { data, error, isLoading, refetch } = useGetSingleOrderQuery({
    user_id: userId,
  });
  const [returnProduct] = useReturnOrderMutation();
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openRateModal, setOpenRateModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectreturnReason, setSelectReturnReason] = useState("");
  const [rating, setRating] = useState(0);
  const [selectReturnMessage, setSelectReturnMessage] = useState();
  const [comment, setComment] = useState("");
  const [addProductReview] = useAddProductReviewMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust this number as needed
  const [selectOrderID, setSelectOrderID] = useState("");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const navigate = useNavigate();

  // Filter orders based on the search term
  const filteredOrders =
    data?.data?.filter((order) =>
      order.items.some((item) =>
        item.product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || []; // Fallback to an empty array if data is undefined

  const handleReturnOpen = (item, orderId) => {
    setSelectedProduct(item);
    setOpenReturnModal(true);
    setSelectOrderID(orderId);
  };

  const handleReturnClose = () => {
    setOpenReturnModal(false);
    setSelectedProduct(null);
    setSelectReturnReason("");
    setSelectReturnMessage("");
  };

  const handleRateOpen = (item) => {
    setSelectedProduct(item);
    setOpenRateModal(true);
  };

  const handleRateClose = () => {
    setOpenRateModal(false);
    setSelectedProduct(null);
    setRating(0);
    setComment("");
  };

  const handleReturnSubmit = async () => {
    const orderId = selectOrderID;
    const itemId = selectedProduct._id;
    const returnReason = selectreturnReason;
    const returnMessage = selectReturnMessage;
    try {
      const response = await returnProduct({
        orderId,
        itemId,
        returnReason,
        returnMessage,
      }).unwrap();

      toast.success("Return Succesfully Processed");
      refetch();
    } catch (error) {
      console.error("Failed to add review:", error);
    }
    handleReturnClose();
  };

  const handleRateSubmit = async () => {
    // Mark the function as async
    const productId = selectedProduct.productId._id; // Replace with the actual product ID
    const userRating = rating; // Replace with the actual rating
    const commentuser = comment; // Replace with the actual comment
    const user = userId;

    try {
      const response = await addProductReview({
        productId,
        userRating,
        commentuser,
        user,
      }).unwrap();

      toast.success("Review Added Successfully");
    } catch (error) {
      console.error("Failed to add review:", error);
    }

    handleRateClose();
  };

  if (isLoading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  // Handle error state


  const returnReasons = [
    "Defective Item",
    "Wrong Item Shipped",
    "Changed My Mind",
    "Better Price Available",
    "Not as Described",
  ];

  // Calculate pagination
  const totalOrders = filteredOrders.length;
  const totalPages = Math.ceil(totalOrders / itemsPerPage);
  const currentOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="my-order-container">
      <h2 className="contact-form-heading mb-0">My Orders</h2>

      {/* <TextField
        variant="outlined"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ marginBottom: "1rem", width: "100%" }}
        className="fixed-width-input"
      /> */}

      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-order-input"
      />




      <Box sx={{ padding: "1.5rem 0" }}>
        {currentOrders.length > 0 ? (
          currentOrders.map((order) => (
            <div key={order._id} className="order-item" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* Order Header */}
              <div className="order-header" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <h3 className="order-id" style={{
                  flexBasis: '100%',
                  whiteSpace: 'nowrap', // Prevents wrapping to the next line
                  overflow: 'hidden',  // Hides the overflowed text
                  textOverflow: 'ellipsis', // Adds the ellipsis (...) when the text overflows
                  maxWidth: '90%' // Sets the maximum width of the order ID to prevent it from overflowing
                }}>
                  Order ID: {order._id}
                </h3>
                <PDFGenerator data={order} />
              </div>

              {/* Order Details */}
              <div className="order-details" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {order.items.map((item) => (
                  <div key={item._id} className="product-item" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <img
                      src={item.product.images[0]}
                      alt={item.product.name}
                      className="product-image"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <div className="product-info">
                      <h4 className="product-name">
                        <Link to={`/product/${item.productId?._id}`}>
                          {item.product?.name}
                        </Link>
                      </h4>
                      <p>Quantity: {item.quantity}</p>
                      <p>Total Item Price: {item.totalItemPrice.toFixed(2)} SEK</p>

                      <div className="button-group" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleRateOpen(item)}
                          style={{ width: '100%' }}
                        >
                          Rate Product
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            if (item.returnDetails?.isReturnRequested) {
                              navigate("/returns");
                            } else {
                              handleReturnOpen(item, order._id);
                            }
                          }}
                          style={{ width: '100%' }}
                        >
                          {item.returnDetails?.isReturnRequested
                            ? "Check My Return Status"
                            : "Return Product"}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Order Summary */}
              <div className="order-summary" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <p>Total Amount: {order.totalAmount.toFixed(2)} SEK</p>
                <p>Payment Status: <strong>{order.paymentStatus}</strong></p>
                <p>Shipping Status: <strong>{order.shippingStatus}</strong></p>
              </div>
            </div>
          ))
        ) : (
          <div>No orders found.</div>
        )}
      </Box>



      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        shape="rounded"
      />

      {/* Return Product Modal */}
      <Dialog open={openReturnModal} onClose={handleReturnClose}>
        <DialogTitle>Return Product</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <p>
              Are you sure you want to return {selectedProduct?.product.name}?
            </p>

            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel>Return Reason</InputLabel>
              <Select
                value={selectreturnReason}
                onChange={(e) => setSelectReturnReason(e.target.value)}
                label="Return Reason"
              >
                {returnReasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              margin="dense"
              label="Return Message (optional)"
              type="text"
              fullWidth
              variant="outlined"
              value={selectReturnMessage}
              onChange={(e) => setSelectReturnMessage(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleReturnClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleReturnSubmit} color="primary">
            Return Product
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rate Product Modal */}
      <Dialog open={openRateModal} onClose={handleRateClose}>
        <DialogTitle>Rate Product</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <p>Rate {selectedProduct?.product.name}</p>

            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              precision={1} // Allows half-star ratings
            />

            <TextField
              margin="dense"
              label="Comment"
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleRateClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRateSubmit} color="primary">
            Submit Rating
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyOrder;
