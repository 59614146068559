import React from "react";
import { Divider } from "@mui/material";
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from "react-router-dom";

const OrderDetails = React.memo(({ cartData }) => {
  
  const navigate = useNavigate();
  
  const handleNavigate = (productId) => {
    navigate(`/product/${productId}`);
  };

  const { t } = useTranslation();
  
  return (
    <div className="order-details-section">
      <h4 className="checkout-title">{t("YourOrder")}</h4>
      <Divider className="divider" />
      <div className="cart-products-container">
        {cartData?.items && cartData.items.length > 0 ? (
          cartData.items.map((item, index) => (
            <div key={index} className="cart-product-item">
              <div className="product-image">
                <img src={`${item.productId.images[0]}`} alt={item.productId.name} />
              </div>
              <div className="product-details">
                <p 
                  className="product-name" 
                  onClick={() => handleNavigate(item.productId._id)} // Adjust this to match your productId property
                  style={{ cursor: 'pointer' }} // Optional: To indicate it's clickable
                >
                  {item.productId.name}
                </p>
                <p className="product-scale">Size: {item.scale}</p>
                <p className="product-quantity">Quantity: {item.quantity}</p>
                <p className="product-price">{`${item.totalItemPrice.toFixed(2)} kr`}</p>
              </div>
            </div>
          ))
        ) : (
          <p>Your cart is empty</p>
        )}
      </div>
    </div>
  );
});

export default OrderDetails;

