import React from 'react';
import './About.css'; // Make sure the CSS is in a separate file like About.css
import Logo from "../../shared/images/logo.png";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <section className="about-section">
      <h2 className="about-heading">{t("About")}</h2>
      <div className="about-grid">
        <div className="about-text">
          <p className="about-para">
            {t("About1")}
          </p>
          <p className="about-para">
            {t("About2")}
          </p>
          <p className='about-para'>
            {t("About3")}
          </p>

        </div>
        <div className="about-image-container">
          <img
            src={Logo}
            alt="Swedebuy about"

          />
        </div>
      </div>
    </section>
  );
};

export default About;
