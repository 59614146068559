import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import PrivacyPolicy from '../components/Privacy/Privacy'

const PrivacyPage = () => {
  return (
    <div>
        <PrivacyPolicy />
    </div>
  )
}

export default PrivacyPage