import React from 'react'
import CheckoutContainer from '../components/Checkout/CheckoutContainer'
import OrderSuccess from '../components/OrderSuccess/Success'

const OrderSuccessPage = () => {
  return (
    <>
        <OrderSuccess />
    </>
  )
}

export default OrderSuccessPage