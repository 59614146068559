import React from "react";
import { Container, Typography, Box, Link, Divider } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Terms and Conditions for Online Purchases
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions (the "Terms") govern the purchase of products and services from{" "}
          <Link href="https://swedebuy.com">https://swedebuy.com</Link> (hereinafter referred to as "we," "us," "our"). By making a purchase, you ("customer," "you," "your") agree to be bound by these Terms. Please read them carefully.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          1. Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Products:</strong> Goods and services offered for sale on our website. <br />
          • <strong>Order:</strong> The request by you to purchase Products from our website. <br />
          • <strong>Website:</strong> www.swedebuy.com <br />
          • <strong>Days:</strong> Calendar days.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          2. Order Process
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Order Placement:</strong> Orders can be placed via our Website by following the instructions on the checkout page. <br />
          • <strong>Order Confirmation:</strong> Once an order is placed, you will receive an email confirmation. This confirmation does not signify our acceptance of your order; it is only to acknowledge that we have received your order. <br />
          • <strong>Acceptance of Order:</strong> Our acceptance of your order will take place when we dispatch the Products to you. We may decline your order if we are unable to obtain authorization for payment, if the Products are unavailable, or for any other reason at our discretion. <br />
          • <strong>Product Availability and Backorder Clause:</strong> The Customer acknowledges and agrees that the availability of products is subject to stock levels at the time of order fulfillment.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          3. Prices and Payment
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Pricing:</strong> All prices are listed in Swedish currency (SEK) and include applicable taxes unless stated otherwise. Prices are subject to change without notice but changes will not affect orders that have already been accepted. <br />
          • <strong>Payment Methods:</strong> At the moment we can only accept payments by credit card. <br />
          • <strong>Future Payment Methods Clause:</strong> In addition to credit card payments, we plan to offer additional payment methods in the future, including PayPal, Swish, and Klarna. These options are currently under development and will be made available to Customers once the necessary integrations are completed. <br />
          • <strong>Payment Terms:</strong> Payment must be made in full at the time of ordering.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          4. Delivery and Risk of Loss
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Delivery Terms:</strong> We will deliver the Products to the address you provide during the order process. Delivery times are estimates and not guaranteed. <br />
          • <strong>Shipping Charges:</strong> Shipping charges will be added to the price of the Products and displayed during the checkout process. <br />
          • <strong>Risk of Loss:</strong> Risk of loss or damage to the Products passes to you upon delivery.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          5. Inspection and Acceptance
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Inspection:</strong> You must inspect the Products upon delivery and notify us of any defects or issues within 14 days. <br />
          • <strong>Acceptance:</strong> If you do not notify us within 14 days, the Products will be deemed accepted and cannot be returned.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          6. Returns and Refunds
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Return Policy:</strong> You may return Products within 14 days of receipt for a full refund, provided the Products are in their original condition and packaging. <br />
          • <strong>Refund Process:</strong> To initiate a return, contact our customer service at info@swedebuy.com. Refunds will be processed within 14 days after we receive the returned Products. The customer will also be able to initiate the return process using their own user account. However, no product can be returned without prior approval. <br />
          • <strong>Non-Returnable Items:</strong> Some items may not be returnable (e.g., perishable goods, customized items). These will be clearly marked on the Website.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          7. Warranties and Liability
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Product Warranty:</strong> We warrant that the Products will be of satisfactory quality and fit for purpose. This warranty does not cover defects caused by misuse or normal wear and tear. <br />
          • <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, our liability for any loss or damage arising from your purchase is limited to the purchase price of the Products.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          8. Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Data Collection:</strong> We collect and process personal data in accordance with our Privacy Policy, which is available{" "}
          <Link href="https://swedebuy.com/privacy#">here</Link>. <br />
          • <strong>Use of Data:</strong> Your personal data will be used to process your order and for other purposes as described in our Privacy Policy.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          9. Compliance with Laws
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Legal Compliance:</strong> You agree to comply with all applicable laws and regulations regarding your purchase and use of the Products.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          10. Force Majeure
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Force Majeure Events:</strong> We are not liable for any delay or failure to perform our obligations if such delay or failure results from events beyond our reasonable control (e.g., natural disasters, war, strikes).
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          11. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Termination for Cause:</strong> We may terminate the contract with immediate effect if you breach these Terms. <br />
          • <strong>Effects of Termination:</strong> Upon termination, you must cease using the Products, and we will not be liable to you for any compensation or refund beyond what is described in these Terms.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          12. Governing Law and Dispute Resolution
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Governing Law:</strong> These Terms are governed by the laws of Sweden. <br />
          • <strong>Dispute Resolution:</strong> Any disputes will be resolved through negotiation, mediation, arbitration, or litigation in the courts of Stockholm, Sweden.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          13. Miscellaneous
        </Typography>
        <Typography variant="body1" paragraph>
          • <strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and us regarding your purchase. <br />
          • <strong>Amendments:</strong> We may amend these Terms from time to time by posting the updated Terms on our Website. Your continued use of the Website after any such changes constitutes your acceptance of the new Terms. <br />
          • <strong>Severability:</strong> If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </Typography>
      </Box>

      <Divider />
    </Container>
  );
};

export default TermsAndConditions;
