import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ChangePassword from '../../../pages/ChangePassword';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/user/`;

export const userApiService = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state
            if (token) {
                headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (payload) => ({
                url: 'create',
                method: 'POST',
                body: { ...payload, source: "user", deviceId: "skeleton_device" },
            }),
        }),
        // Fetch all addresses for a user
        getUserAddresses: builder.query({
            query: (userId) => ({
                url: `get/${userId}/shipping-addresses`, // Corrected URL
                method: 'GET',
            }),
        }),

        // Delete a specific address for a user
        deleteUserAddress: builder.mutation({
            query: ({ userId, addressId }) => ({
                url: `delete/${userId}/shipping-addresses/${addressId}`, // Corrected URL
                method: 'DELETE',
            }),
        }),
        updateUserAddress: builder.mutation({
            query: ({ userId, addressId, addressLine1, postalCode, phone }) => ({
                url: `update/${userId}/shipping-addresses/${addressId}`, // Corrected URL
                method: 'PATCH',
                body: {
                    addressLine1,
                    postalCode,
                    phone,
                },
            }),
        }),
        addUserAddress: builder.mutation({
            query: ({ userId,  addressLine1, postalCode, phone, country }) => ({
                url: `addShippingAdress/${userId}`, // Corrected URL
                method: 'POST',
                body: {
                    addressLine1,
                    postalCode,
                    phone,
                    country
                },
            }),
        }),
        updateUserDetails: builder.mutation({
            query: ({ userId, first_name, last_name, phone }) => ({
                url: `updateUser/${userId}`, // URL structure for updating user details
                method: 'PATCH',
                body: {
                    first_name,
                    last_name,
                    phone,
                },
            }),
        }),
        changePassword: builder.mutation({
            query: ({ currentPassword, newPassword }) => ({
                url: `change-password`, // URL structure for updating user details
                method: 'POST',
                body: {
                    currentPassword,
                    newPassword,
                },
            }),
        }),
    }),
});

export const {
    useRegisterMutation,
    useUpdateUserAddressMutation,
    useGetUserAddressesQuery, // Export hook for fetching addresses
    useDeleteUserAddressMutation, // Export hook for deleting address
    useUpdateUserDetailsMutation,
    useChangePasswordMutation,
    useAddUserAddressMutation
} = userApiService;
