import {Suspense, lazy} from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import ProtectedRoute from "../components/ProtectedRoute";
import Wrapper from "../components/wrapper/Wrapper";
import Loader from "../components/Loader/Loader";
import CartPage from "../pages/CartPage";
import OrderSuccessPage from "../pages/OrderSuccessPage";
import AboutPage from "../pages/AboutPage";
import PrivacyPage from "../pages/Privacy";
import SearchPage from "../pages/SearchPage";
import VerifyEmailPage from "../pages/EmailVerifyPage";
import EmailSentPage from "../pages/EmailSent";
import TOkenVerifyPage from "../pages/TokenVerify";
import ReturnPage from "../pages/ReturnPage";
import TermPage from "../pages/TermPage";

const LoginForm = lazy(() => import('../components/Auth/LoginForm'));
const SignupForm = lazy(() => import("../components/Auth/SignupForm"));
const AccountPage = lazy(() => import("../pages/AccountPage"));
const BrandsPage = lazy(() => import("../pages/BrandsPage"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const CheckoutPage = lazy(() => import("../pages/CheckoutPage"));
const ContactPage = lazy(() => import("../pages/ContactPage"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const HomePage = lazy(() => import("../pages/HomePage"));
const MyOrderPage = lazy(() => import("../pages/MyOrderPage"));
const ProductDetailsPage = lazy(() => import("../pages/ProductDetailsPage"));
const ProductListingPage = lazy(() => import("../pages/ProductListingPage"));
const VerifyCodePage = lazy(() => import("../pages/VerifyCodePage"));
const NotFound = lazy(() => import("../components/notFound/NotFound"));

const AppRoutes = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="" element={<Wrapper/>}>
                    <Route index element={<HomePage/>}/>
                    <Route path="/product/:id" element={<ProductDetailsPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/search/:term" element={<SearchPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/verify-email" element={<VerifyEmailPage/>}/>
                    <Route path="/verify" element={<TOkenVerifyPage/>}/>
                    <Route path="/return" element={<ReturnPage/>}/>
                    <Route path="/terms" element={<TermPage/>}/>
                    <Route path="/email-sent" element={<EmailSentPage/>}/>
                    <Route path="/privacy" element={<PrivacyPage/>}/>
                    <Route path="/auth/login" element={<ProtectedRoute><LoginForm/></ProtectedRoute>}/>
                    <Route path="/auth/register" element={<ProtectedRoute><SignupForm/></ProtectedRoute>}/>
                    <Route path="/brands" element={<BrandsPage/>}/>
                    <Route path="/account" element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
                    <Route path="/address-details" element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
                    <Route path="/returns" element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
                    <Route path="/my-order" element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
                    <Route path="/cards" element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
                    <Route path="/products" element={<ProductListingPage heading={"All Products"}/>}/>
                    <Route path="/forgot-password" element={<ProtectedRoute><ForgotPasswordPage/></ProtectedRoute>}/>
                    <Route path="/auth/verify-code" element={<ProtectedRoute><VerifyCodePage/></ProtectedRoute>}/>
                    <Route path="/auth/set-password" element={<ProtectedRoute><ChangePassword/></ProtectedRoute>}/>
                    <Route path="/cart" element={<CartPage/>}/>
                    <Route path="/auth/checkout" element={<PrivateRoute><CheckoutPage/></PrivateRoute>}/>
                    <Route path="/auth/order-success" element={<PrivateRoute><OrderSuccessPage/></PrivateRoute>}/>
                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
