import Logo from '../../shared/images/logo.png'
import './style.css'

const Loader = () => {
  return (
    <div className="loader">
        <img src={Logo}/>
    </div>
  )
}

export default Loader