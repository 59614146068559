// src/redux/cookieConsentSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  // Default to null to indicate no decision has been made yet
  isAccepted: Cookies.get('cookieConsent') === 'true' ? true : Cookies.get('cookieConsent') === 'false' ? false : null,
  locationConsent: Cookies.get('locationConsent') === 'true' ? true : false,
  experienceConsent: Cookies.get('experienceConsent') === 'true' ? true : false,
  preferencesConsent: Cookies.get('preferencesConsent') === 'true' ? true : false,
};

const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    acceptCookies: (state) => {
      state.isAccepted = true;
      // Set all consents to true when accepting all cookies
      state.locationConsent = true;
      state.experienceConsent = true;
      state.preferencesConsent = true;

      // Save to cookies for persistent consent
      Cookies.set('cookieConsent', 'true', { expires: 365 });
      Cookies.set('locationConsent', 'true', { expires: 365 });
      Cookies.set('experienceConsent', 'true', { expires: 365 });
      Cookies.set('preferencesConsent', 'true', { expires: 365 });
    },
    declineCookies: (state) => {
      state.isAccepted = false;
      // Reset all consents to false when declining
      state.locationConsent = false;
      state.experienceConsent = false;
      state.preferencesConsent = false;

      // Save to cookies for persistent consent
      Cookies.set('cookieConsent', 'false', { expires: 365 });
      Cookies.set('locationConsent', 'false', { expires: 365 });
      Cookies.set('experienceConsent', 'false', { expires: 365 });
      Cookies.set('preferencesConsent', 'false', { expires: 365 });
    },
    trackLocationConsent: (state) => {
      state.locationConsent = true;
      Cookies.set('locationConsent', 'true', { expires: 365 });
    },
    enhanceExperienceConsent: (state) => {
      state.experienceConsent = true;
      Cookies.set('experienceConsent', 'true', { expires: 365 });
    },
    trackPreferencesConsent: (state) => {
      state.preferencesConsent = true;
      Cookies.set('preferencesConsent', 'true', { expires: 365 });
    },
  },
});

// Export the actions
export const {
  acceptCookies,
  declineCookies,
  trackLocationConsent,
  enhanceExperienceConsent,
  trackPreferencesConsent,
} = cookieConsentSlice.actions;

export default cookieConsentSlice.reducer;
