import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import HomeProduct from "../ProductComponent/HomeProduct";
import Product from "../ProductComponent/Product";
import ProductCard from "./ProductCard";
import "./Slider.css";

const ProductSlider = ({ products }) => {
  const baseUrl = process.env.REACT_APP_IMAGE_ENDPOINT;
  const productCount = products?.length || 0;

  // Adjust settings based on product count
  let slidesToShow = Math.min(productCount, 4); // Ensure no more than 4 products shown at once
  let slidesToScroll = Math.min(productCount, 4);

  // Ensure that there is at least one card visible on larger screens, even if there are few products
  const responsiveSettings = [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: Math.max(productCount < 3 ? productCount : 3, 2), // At least 2 cards
        slidesToScroll: Math.max(productCount < 3 ? productCount : 3, 2),
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: Math.max(productCount < 2 ? productCount : 2, 1), // At least 1 card
        slidesToScroll: Math.max(productCount < 2 ? productCount : 2, 1),
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow,
    slidesToScroll,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: responsiveSettings,
  };

  return (
    <div className="slider-container-2">
      <Slider {...settings}>
        {products?.map((product, index) => (
          <ProductCard key={index} data={product} />
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
