import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartModalOpen: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openCartModal(state) {
      state.isCartModalOpen = true;
    },
    closeCartModal(state) {
      state.isCartModalOpen = false;
    },
  },
});

export const { openCartModal, closeCartModal } = modalSlice.actions;
export default modalSlice.reducer;
