import * as React from 'react';
import { Box, IconButton, Typography, Paper, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromCart, updateItemQuantity } from '../../redux/Slices/Cart'; // Import actions
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useTranslation } from 'react-i18next';

const CartTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize useNavigate hook
    const cartItems = useSelector((state) => state.cartSlice.items); // Get cart items from Redux store
    const { t } = useTranslation();
    // Handle quantity change
    const handleQuantityChange = (productId, size, color, variation, newQuantity, quantityDiscount, stock) => {
        if (newQuantity > 0) {
            dispatch(updateItemQuantity({ productId, size, color, variation, quantity: parseInt(newQuantity, 10), quantityDiscount, stock }));
        }
    };

    // Handle item deletion
    const handleDelete = (productId, size, color, variation) => {
        dispatch(removeItemFromCart({ productId, size, color, variation }));
    };

    // Navigate to product page
    const handleProductClick = (productId) => {
        navigate(`/product/${productId}`);
    };

    if (cartItems.length === 0) {
        return <p className="empty-cart-message">
            {t("No items in the cart")}
        </p>;
    }

    return (
        <Paper
            sx={{
                padding: 2,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                border: '1px solid #ddd',
                borderRadius: 2
            }}
        >
            {cartItems.map((item) => (
                <Box
                    key={item.productId}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingY: 2,
                        borderBottom: '1px solid #eee',
                        '&:last-child': { borderBottom: 'none' },
                        '@media (max-width: 600px)': { flexDirection: 'column', alignItems: 'flex-start' },
                    }}
                >
                    <img
                        src={item.imageUrl || 'default-image.jpg'}
                        alt={item.name}
                        style={{
                            width: 60,
                            height: 60,
                            borderRadius: 8,
                            marginRight: 12,
                            cursor: 'pointer',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)'
                        }}
                        onClick={() => handleProductClick(item.productId)}
                    />
                    <Box sx={{ flex: 1, minWidth: 0, '@media (max-width: 600px)': { width: '100%', marginTop: 1 } }}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 500,
                                cursor: 'pointer',
                                color: '#333',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%', // Ensures it doesn’t expand
                            }}
                            onClick={() => handleProductClick(item.productId)}
                        >
                            {item.name || 'Product Name'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', fontSize: '0.875rem' }}>
                           
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
                        <IconButton
                            onClick={() =>
                                handleQuantityChange(
                                    item.productId,
                                    item.size,
                                    item.color,
                                    item.variation,
                                    item.quantity - 1,
                                    item.quantityDiscount,
                                    item.stock,
                                )
                            }
                            disabled={item.quantity <= 1}
                            sx={{
                                backgroundColor: '#f5f5f5',
                                '&:hover': { backgroundColor: '#eee' },
                                padding: '6px',
                                color: '#666'
                            }}
                        >
                            <RemoveIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="body2" sx={{ marginX: 1, color: '#333' }}>
                            {item.quantity}
                        </Typography>
                        <IconButton
                            onClick={() =>
                                handleQuantityChange(
                                    item.productId,
                                    item.size,
                                    item.color,
                                    item.variation,
                                    item.quantity + 1,
                                    item.quantityDiscount,
                                    item.stock,
                                )
                            }
                            sx={{
                                backgroundColor: '#f5f5f5',
                                '&:hover': { backgroundColor: '#eee' },
                                padding: '6px',
                                color: '#666'
                            }}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box sx={{ minWidth: 80, textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#333' }}>
                            {item.basePrice.toFixed(2)} Kr
                        </Typography>
                    </Box>
                    <Button
                        onClick={() => handleDelete(item.productId, item.size, item.color, item.variation)}
                        sx={{
                            marginLeft: 2,
                            color: '#999',
                            fontSize: '0.75rem',
                            textTransform: 'none',
                            '&:hover': { color: '#333' }
                        }}
                    >
                        {t("Remove")}
                    </Button>
                </Box>
            ))}
        </Paper>

    );
};

export default CartTable;
