import React from "react";
import "./Footer.css";
import Logo from "../../shared/images/logo.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const nav = useNavigate();
    const { t } = useTranslation();
    return (
        <footer>
            <div className="text-center text-md-start p-5">
                <div className="footer-wrap">
                    <div className="about">
                        <img src={Logo} alt="" style={{ "width": "25%" }} />

                        <p className="py-3">
                        
                        </p>
                        <div className="social-media">
                            <a href="" className="me-2 text-reset">
                                <FacebookIcon />
                            </a>
                            <a href="" className="me-2 text-reset">
                                <InstagramIcon />
                            </a>
                        </div>
                    </div>

                    <div className="company">
                        <h6>
                        {t("POLICIES")}
                        </h6>
                        <p>
                            <a href="#" onClick={() => nav("/privacy")}>
                            {t("Privacy Policy")}
                            </a>
                        </p>
                        <p>
                            <a href="#">
                            {t("CookiePolicy")}
                            </a>
                        </p>
                    </div>
                    <div className="company">
                        <h6>Information</h6>
                        <p>
                            <a href="#" onClick={() => nav("/return")}>
                            {t("ShippingReturn")}
                            </a>
                        </p>
                        <p>
                            <a href="#" onClick={() => nav("/terms")}>
                            {t("Terms")}
                            </a>
                        </p>
                        <p>
                            <a href="#" onClick={() => nav("/contact")}>
                            {t("Contact")}
                            </a>
                        </p>
                    </div>

                    
                </div>
            </div>
        </footer>
    );
};

export default Footer;
