import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css'; // Import the CSS file
import { useDispatch, useSelector } from 'react-redux';
import { acceptCookies, declineCookies, trackLocationConsent, trackPreferencesConsent, enhanceExperienceConsent } from '../../redux/Slices/Cookie';
import { Button, Box, Divider, Typography } from '@mui/material';

const CookieConsent = () => {
  const dispatch = useDispatch();
  const isAccepted = useSelector((state) => state.cookieConsent.isAccepted);

  // States to track acceptance or decline for each category
  const [locationConsent, setLocationConsent] = useState(null);
  const [experienceConsent, setExperienceConsent] = useState(null);
  const [preferencesConsent, setPreferencesConsent] = useState(null);
  const [declined, setDeclined] = useState(false); // Track if any consent is declined

  // State to control the visibility of the consent banner
  const [showConsent, setShowConsent] = useState(true);

  const API_TOKEN = process.env.REACT_APP_IP_TOKEN; // Replace with your actual ipinfo.io token
  const SERVER_URL = `${process.env.REACT_APP_BASE_URL}/ip/create`;

  const fetchIpInfo = async () => {
    try {
      const response = await fetch(`https://ipinfo.io/json?token=${API_TOKEN}`);
      if (!response.ok) throw new Error('Failed to fetch IP information');

      const data = await response.json();
      const { country, region, city } = data;

      await fetch(SERVER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country, region, city }),
      });

      console.log('Location data sent to server successfully');
    } catch (error) {
      console.error("Error fetching IP information or sending to server:", error);
    }
  };

  const handleAcceptAll = () => {
    dispatch(acceptCookies());
    setLocationConsent(true);
    setExperienceConsent(true);
    setPreferencesConsent(true);
    setDeclined(false); // Reset declined state when accepting all
  };

  const handleTrackLocation = () => {
    fetchIpInfo();
    dispatch(trackLocationConsent());
    setLocationConsent(true);
    setDeclined(false); // Reset declined state when accepting
  };

  const handleEnhanceExperience = () => {
    dispatch(acceptCookies());
    dispatch(enhanceExperienceConsent());
    setExperienceConsent(true);
    setDeclined(false); // Reset declined state when accepting
  };

  const handleTrackPreferences = () => {
    dispatch(trackPreferencesConsent());
    setPreferencesConsent(true);
    setDeclined(false); // Reset declined state when accepting
  };

  const handleDeclineLocation = () => {
    setLocationConsent(false);
    setDeclined(true); // Set declined state to true when declining
  };

  const handleDeclineExperience = () => {
    setExperienceConsent(false);
    setDeclined(true); // Set declined state to true when declining
  };

  const handleDeclinePreferences = () => {
    setPreferencesConsent(false);
    setDeclined(true); // Set declined state to true when declining
  };

  // Check if all consents are given, and close the banner if so
  useEffect(() => {
    if (locationConsent !== null && experienceConsent !== null && preferencesConsent !== null) {
      setShowConsent(false);
    }
  }, [locationConsent, experienceConsent, preferencesConsent]);

  if (!showConsent ) {
    return null;
  }

  return (
    <div className="wrapper">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
        <Typography variant="body1" sx={{ marginBottom: 1, textAlign: 'center' }}>
          We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.
        </Typography>

        {/* Location Consent */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center', color: '#333' }}>Track Location for Ad Services</Typography>
          <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleTrackLocation}
              disabled={locationConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeclineLocation}
              disabled={locationConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Decline
            </Button>
          </Box>
        </Box>

        <Divider sx={{ width: '100%', marginBottom: 2 }} />

        {/* Enhance Experience Consent */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center', color: '#333' }}>Enhance User Experience</Typography>
          <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEnhanceExperience}
              disabled={experienceConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeclineExperience}
              disabled={experienceConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Decline
            </Button>
          </Box>
        </Box>

        <Divider sx={{ width: '100%', marginBottom: 2 }} />

        {/* Track Preferences Consent */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center', color: '#333' }}>Track Preferences</Typography>
          <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleTrackPreferences}
              disabled={preferencesConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeclinePreferences}
              disabled={preferencesConsent !== null}
              sx={{ borderRadius: '50px', paddingX: 3, paddingY: 1 }}
            >
              Decline
            </Button>
          </Box>
        </Box>

        <Divider sx={{ width: '100%', marginBottom: 2 }} />

        {/* Accept All Cookies */}
        <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleAcceptAll}
            disabled={locationConsent === false || experienceConsent === false || preferencesConsent === false}
            sx={{ borderRadius: '50px', paddingX: 5, paddingY: 1.2 }}
          >
            Accept All
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CookieConsent;
