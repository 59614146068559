import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Divider, Grid, CircularProgress, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetReturnedProductsQuery } from '../../redux/Apis/Order'; // Adjust the import based on your project structure
import './Returns.css'; // Import your CSS for styling
import { Trash } from 'lucide-react';
import { toast } from 'react-toastify';

const SavedCards = () => {
    const { isLoggedIn, userId } = useSelector((state) => state.authSlice);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const getUserCards = async () => {
        try {
            const response = await fetch(`${BASE_URL}/payment/get-user-cards`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch cards');
            }

            const data = await response.json();
            setCards(data.paymentMethods || []);
       
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCard = async (cardId) => {
        try {
            const response = await fetch(`${BASE_URL}/payment/delete-user-card`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, paymentMethodId: cardId }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete card');
            }
    
            // Show success toast
            toast.success('Card deleted successfully!');
    
            // Remove the card from the UI
            getUserCards();
        } catch (error) {
            console.error('Delete error:', error);
            toast.error('Error deleting card.');
        }
    };
    

    useEffect(() => {
        if (userId) {
            getUserCards();
        }
    }, [userId]);

    return (
        <div className='my-order-container' style={{ padding: '20px' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                My Cards
            </Typography>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                    <CircularProgress />
                </div>
            ) :  cards.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#757575' }}>
                        You have no saved cards yet.
                    </Typography>
                </div>
            ) : (
                <Grid container spacing={5}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={12} key={index}>
                            <Card variant="outlined" sx={{
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                padding: '10px',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                                }
                            }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        {card.card.brand}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
                                        **** **** **** {card.card.last4}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem' }}>
                                        {card.card.exp_month}/{card.card.exp_year}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <IconButton
                                            onClick={() => handleDeleteCard(card.id)} 
                                            sx={{ color: '#f44336' }}
                                        >
                                            <Trash size={20} />
                                        </IconButton>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
}

export default SavedCards;
