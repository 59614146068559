import React, { useState, useEffect } from 'react';
import './NewsletterModal.css';
import { useGetNewsletterQuery, useSubscribeToNewsletterMutation } from '../../redux/Apis/Newsletter';
import { toast } from 'react-toastify';


const NewsletterModal = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [email, setEmail] = useState('');
    const { data: newsletterData, isLoading, error } = useGetNewsletterQuery();
    const [subscribeToNewsletter] = useSubscribeToNewsletterMutation();

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem('newsletterClosed', 'true');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newsletter?._id && email) {
            try {
                await subscribeToNewsletter({ id: newsletter._id, email }).unwrap();
                toast.success("Thank you for subscribing to swedebuy")
                setIsVisible(false);
                localStorage.setItem('newsletterClosed', 'true');
            } catch (error) {
                console.error('Subscription failed:', error);
                toast.error(error);
            }
        }
    };

    useEffect(() => {
        const newsletterClosed = localStorage.getItem('newsletterClosed');
        if (newsletterClosed === 'true') {
            setIsVisible(false);
        }
    }, []);

    if (!isVisible) return null;

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading newsletter data</div>;

    const newsletter = newsletterData?.data?.[0]; // Access the first item in the data array

    return (
        <div className="newsletter-modal-overlay">
            <div className="newsletter-modal">
                <button className="close-button" onClick={handleClose}>
                    &times;
                </button>
                <div className="newsletter-content">
                    <div className="newsletter-image">
                        <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${newsletter?.imageUrl}` || "https://djangogreatkart.com/static/images/banners/cover.jpg"}
                            alt="Newsletter"
                        />
                    </div>
                    <div className="newsletter-text">
                        <h2>{newsletter?.title || "UP TO 30% OFF"}</h2>
                        <p>
                            {newsletter?.description || "Subscribe to the SWEDEBUY eCommerce newsletter to receive timely updates from your favorite products."}
                        </p>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email address"
                                className="newsletter-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit" className="newsletter-submit">
                                SUBMIT
                            </button>
                        </form>
                        <div className="newsletter-social">
                            {/* Add social icons here if needed */}
                        </div>
                        <div className="newsletter-no-thanks">
                            <input type="checkbox" id="no-thanks" onClick={handleClose} />
                            <label htmlFor="no-thanks">No, Thanks</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterModal;
