import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/newsletter/`;

export const newsletterApiService = createApi({
    reducerPath: 'newsletterApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state
            if (token) {
                headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getNewsletter: builder.query({
            query: () => ({
                url: 'get',
                method: 'GET',
            }),
        }),
        subscribeToNewsletter: builder.mutation({
            query: ({ id, email }) => ({
                url: `subscribe/${id}`,
                method: 'POST',
                body: { email },
            }),
        }),
    }),
});

export const {
    useGetNewsletterQuery,
    useSubscribeToNewsletterMutation
} = newsletterApiService;
