import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSendVerificationTokenMutation, useVerifyTokenMutation } from '../../redux/Apis/Auth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TokenVerify = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extract token from the query parameters
    const token = new URLSearchParams(location.search).get('token');

    const [verify, { isSuccess, isLoading, error }] = useVerifyTokenMutation();

    useEffect(() => {
        if (token) {
            // Run the mutation with the token in the request body
            verify({ token });
        }
    }, [token, verify]);

    return (
        <Container
            maxWidth="sm"
            sx={{
                my: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    maxWidth: 400,
                    width: '100%'
                }}
            >
                {isLoading && (
                    <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
                        Verifying your token, please wait...
                    </Typography>
                )}

                {isSuccess && (
                    <>
                        <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                                fontSize: '1.5rem'
                            }}
                        >
                            Email Verified!
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'text.secondary',
                                mb: 3
                            }}
                        >
                            Your email has been successfully verified. You can now log in to your account.
                        </Typography>
                    </>
                )}

                {error && (
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'error.main',
                            mb: 3
                        }}
                    >
                        {error?.data?.message || 'Token verification failed. Please try again.'}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/auth/login')}
                    sx={{
                        mt: 2,
                        px: 4,
                        py: 1,
                        borderRadius: 1
                    }}
                >
                    Login
                </Button>
            </Box>
        </Container>
    );
};

export default TokenVerify;