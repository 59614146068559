import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";
import {
    persistReducer,
    persistStore,
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import createIndexedDBStorage from "redux-persist-indexeddb-storage";
import { authApiService } from "../Apis/Auth";
import { brandApiService } from "../Apis/Brands";
import { cartApiService } from "../Apis/Cart";
import { feedbackApiService } from "../Apis/Feedback";
import { generalApiService } from "../Apis/General";
import { reviewsApiService } from "../Apis/Review";
import { productApiService } from "../Apis/Products";
import { userApiService } from "../Apis/User";
import { categoryApiService } from "../Apis/Category";
import { SubcategoryApiService } from "../Apis/Subcategory";
import { orderApiService } from "../Apis/Order";
import AuthSlice, { logout } from "../Slices/Auth";
import GeneralSlice from "../Slices/General";
import cookieConsentReducer from "../Slices/Cookie";
import cartSlice from "../Slices/Cart";
import modalReducer from "../Slices/CartModal";
import { homeSettingsApiService } from "../Apis/HomeSettings";
import categoryReducer from "../Slices/Category"; // Import the CategorySlice reducer
import languageReducer from "../Slices/Language";
import { KupongApiService } from "../Apis/Coupon";
import { headerMessageApiService } from "../Apis/HeaderSettings";
import { searchApiService } from "../Apis/Search";
import { exchangeRateApiService } from "../Apis/Exchange";
import { newsletterApiService } from "../Apis/Newsletter";
import { carousalApiService } from "../Carousal";

// Error handling middleware to show toast notifications for API errors
const apiErrorHandler = (store) => (next) => (action) => {
    if (action.type.endsWith("/rejected")) {
        if (action?.payload) {
            let { data, status } = action?.payload;
            let { message } = data;

            toast.error(message);

            if (status === 500) {
                store.dispatch(logout());
            }
        }
    }

    return next(action);
};

// Configure IndexedDB storage
const indexedDBStorage = createIndexedDBStorage({
    name: "redux-persist", // Name of the IndexedDB database
    storeName: "persistStore", // Name of the store in IndexedDB
});

const persistConfig = {
    key: "root",
    storage: indexedDBStorage,
    blacklist: ["categoryApiService"], // Exclude large slices or reducers to prevent storage overflow
};

// Combine all the reducers
export const rootReducers = combineReducers({
    authSlice: AuthSlice,
    generalSlice: GeneralSlice,
    cartSlice: cartSlice,
    modal: modalReducer,
    cookieConsent: cookieConsentReducer,
    language: languageReducer,
    category: categoryReducer, // Add the CategorySlice reducer
    [authApiService.reducerPath]: authApiService.reducer,
    [productApiService.reducerPath]: productApiService.reducer,
    [userApiService.reducerPath]: userApiService.reducer,
    [brandApiService.reducerPath]: brandApiService.reducer,
    [feedbackApiService.reducerPath]: feedbackApiService.reducer,
    [cartApiService.reducerPath]: cartApiService.reducer,
    [generalApiService.reducerPath]: generalApiService.reducer,
    [orderApiService.reducerPath]: orderApiService.reducer,
    [homeSettingsApiService.reducerPath]: homeSettingsApiService.reducer,
    [categoryApiService.reducerPath]: categoryApiService.reducer,
    [SubcategoryApiService.reducerPath]: SubcategoryApiService.reducer,
    [KupongApiService.reducerPath]: KupongApiService.reducer,
    [headerMessageApiService.reducerPath]: headerMessageApiService.reducer,
    [searchApiService.reducerPath]: searchApiService.reducer,
    [reviewsApiService.reducerPath]: reviewsApiService.reducer,
    [exchangeRateApiService.reducerPath]: exchangeRateApiService.reducer,
    [newsletterApiService.reducerPath]: newsletterApiService.reducer,
    [carousalApiService.reducerPath]: carousalApiService.reducer
});

// Apply persistReducer to the combined reducers
const persistedReducer = persistReducer(persistConfig, rootReducers);

// Configure the Redux store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(authApiService.middleware)
            .concat(productApiService.middleware)
            .concat(userApiService.middleware)
            .concat(brandApiService.middleware)
            .concat(feedbackApiService.middleware)
            .concat(cartApiService.middleware)
            .concat(generalApiService.middleware)
            .concat(orderApiService.middleware)
            .concat(categoryApiService.middleware)
            .concat(SubcategoryApiService.middleware)
            .concat(homeSettingsApiService.middleware)
            .concat(KupongApiService.middleware)
            .concat(headerMessageApiService.middleware)
            .concat(searchApiService.middleware)
            .concat(reviewsApiService.middleware)
            .concat(exchangeRateApiService.middleware)
            .concat(newsletterApiService.middleware)
            .concat(carousalApiService.middleware)
         
            .concat(apiErrorHandler),
});

// Create the persistor
export const persistor = persistStore(store);

// Setup listeners for refetching on focus or reconnect
setupListeners(store.dispatch);
