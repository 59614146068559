import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import TermsAndConditions from '../components/Term/Term'

const TermPage = () => {
  return (
    <div>
        <TermsAndConditions />
    </div>
  )
}

export default TermPage