import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../redux/Apis/Auth";
import { StandardInputTheme } from "./StandardInputTheme";
import "./style.css"; // Assuming you have a CSS file for styling

const ChangePasswordForm = () => {
  const { state } = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: state?.email } });
  const [resetPassword, { data, isSuccess, isLoading }] =
    useResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate("/auth/login");
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    resetPassword(data);
  };

  return (
    <ThemeProvider theme={StandardInputTheme}>
      <div className="login-form-container shadow">
        <form>
          <h2 className="loginpage_heading">Change Password</h2>
          <div className="input-group">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className="input-group">
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel htmlFor="standard-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                  />
                </FormControl>
              )}
            />
          </div>
          <button
            type="submit"
            className="btn btn-success w-100 mt-3"
            onClick={handleSubmit(onSubmit)}
          >
            Update Password
          </button>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default ChangePasswordForm;
