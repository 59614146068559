// src/redux/languageSlice.js
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../shared/i18n/i18n'; // Import your i18n config

const languageSlice = createSlice({
  name: 'language',
  initialState: 'swe', // Default language
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload;
      i18n.changeLanguage(language); // Change language in i18n
      return language; // Update Redux state
    }
  }
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
